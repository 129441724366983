import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import rows from './hexagramRows'; // Adjust the path as necessary

const order = "地山水風雷火澤天";

function extractInitials(inputString) {
  // Split the string into words, considering both spaces and hyphens as delimiters.
  // This is done by replacing hyphens with spaces and then splitting by space.
  const words = inputString.replace(/-/g, ' ').split(' ');

  // Map each word to its first character, capitalize it, and then join without a delimiter.
  const initials = words.map(word => word.charAt(0).toUpperCase()).join('');

  return initials;
}

const StyledHexagramsDisplay = styled.div`
  display: flex;
  flex-direction: column;
  .row:last-child .cell {
    border-bottom: none; // Removes the bottom border from cells in the last row
  }
  .cell:last-child {
    border-right: none; // Removes the right border from the last cell in each row
  }
`;

const Row = styled.div`
  display: flex;
  max-width: 420px; /* Set your desired max width */
  width:100%;
  margin: auto;
  border-bottom: 1px solid #ddd;
  justify-content: center

`;

const Cell = styled(ButtonBase)`
  width:  ${props => props.narrow?"12.50%":"14.25%"};
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0px !important;
`;

const Number = styled.span`
  font-size: 1.2em; // Smaller font size for the number
  padding: 0px;
  margin: 0px;
`;

const HexagramImage = styled.span`
  font-size: 1.8em; // Smaller font size for the number
  margin-bottom: 4px; // Space between the number and the content
  padding: 0px;
  margin: 0px;
`;

const EmptyCell = styled(Cell)`
  // Style for empty cell, you might want to make it invisible or differently styled
  pointer-events: none; // Disable click events on empty cells
`;


// Style for the secondary text in the hexagram, like descriptions
const HexagramTextSec = styled.div`
  font-size: 1.4em; // Smaller font size for secondary text
  color: #666; // A lighter color for secondary text
  // Add any additional styles you need
  margin-bottom: 2px;
`;


const convSqTb = (n) => {


  const i = Math.floor((n-1) / 8);
  const j = (n - 1) % 8;

  const cell = Object.entries(rows)
  .filter(row => (row[1].d === order[i] || row[1].d[1] === order[i]))
  .filter(cell => (cell[1].d === order[j] || cell[1].d[0] === order[j]));

  return cell[0][0];

}

const HexagramsDisplay = ({onClick, isLiteMode, isSongMode}) => {

  const skip = [9, 20, 30, 30, 30, 33, 33, 35, 51, 60, 61, 63, 64,64];
  const double = [9, 13, 14, 21, 25, 26, 28, 34, 36, 37, 54, 61, 62];
  let n = 1;

  const mode = isSongMode?"song":"table";

  const HexagramTextMain = !isLiteMode?styled.div`
    font-size: 1.8em; // Larger font size for main text
    font-weight: bold; // Bold for emphasis
    // Add any additional styles you need
    margin-bottom: 2px;
    `:styled.div`
    font-size: 1.5em; // Larger font size for main text
    margin: 2px;
    `;

  const findPair = (num) => {
    // Implement the logic for findPair
  };

  const rowCount = (mode == "song")?13:8;
  const narrow = (mode == "song")?false:true;
  return (

    <StyledHexagramsDisplay>

      {[...Array(rowCount)].map((_, rowIndex) => {
        let cells = [];
        let cellCount = 0;
        const rowLen = (mode == "song")?7:8;

        while (cellCount < rowLen && n <= 64) {


          const m = (mode == "song")?n:convSqTb(n);
          n++;

          const content = rows[m.toString()];
          const img = String.fromCharCode(19904 + parseInt(m) - 1);

          const isDouble  = (mode == "song")?double.includes(m):false
          const skipCount = (mode == "song")?skip.filter(x => x === m).length:0

          let cellContent = content.d.length >= 2 ? (
            <div>
              {!isLiteMode && <HexagramTextSec>{content.d}</HexagramTextSec>}
              <HexagramTextMain sx={{fontSize: "1.2em", fontWeight: "normal"}}>{!isLiteMode?content.c:extractInitials(content.p)}</HexagramTextMain>
            </div>
          ) : (
            <div>
              <HexagramTextMain sx={{fontSize: "1.2em", fontWeight: "normal"}}>{!isLiteMode?content.c:extractInitials(content.p)}</HexagramTextMain>
              {!isLiteMode && <HexagramTextSec>為{content.d}</HexagramTextSec>}
            </div>
          );
  

          const idx = m;

          cells.push(
            <Cell narrow={narrow} key={`${rowIndex}-${cellCount}`} onClick={() => onClick(idx)}>
                {!isLiteMode && <Number>{m}</Number>}
                {!isLiteMode && <HexagramImage className="symbol">
                  <span>{img}</span>
                </HexagramImage>}
                {cellContent}
            </Cell>
          );

          cellCount++;

          // Instead of attaching onClick, add EmptyCell without onClick
          if (isDouble && cellCount < 7) {
            cells.push(<EmptyCell key={`${rowIndex}-${cellCount}-double`} />);
            cellCount++;
          }

          // Same for skip
          for (let i = 0; i < skipCount && cellCount < 7; i++) {
            cells.push(<EmptyCell key={`${rowIndex}-${cellCount}-skip-${i}`} />);
            cellCount++;
          }

        }

        return <Row key={rowIndex}>{cells}</Row>;
      })}

    </StyledHexagramsDisplay>

  );
};

export default HexagramsDisplay;